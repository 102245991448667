<template>
  <fragment>
    <app-header title="Settings"></app-header>
  <div class="container px-0">
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <label class="form-label">Existing Screen</label>
            <screen-select holder="" v-model="existingScreen"/>
          </div>
          <div class="col-auto">
            <label class="form-label">&nbsp;</label>
            <i class="far fa-exchange"></i>
          </div>
          <div class="col">
            <label class="form-label">New Screen</label>
            <screen-select holder="" v-model="newScreen"/>
          </div>
        </div>

        <div class="mt-3 text-end">
          <button
            class="btn btn-primary btn-transfer-warning"
            @click="handleTransfer"
          >
            Transfer
          </button>
        </div>
      </div>
    </div>
  </div>
</fragment>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/screens'
import { toast } from '@chameleon/chameleon-vue'
import ScreenSelect from '@/components/forms/ScreenSelect'
import { http } from '@/utils'
import swal from 'sweetalert'

export default {
  name: 'ScreenTransfer',

  mixins: [
    stateMapper
  ],

  components: {
    ScreenSelect,
  },

  computed: {
    ...helperComputed
  },

  data () {
    return {
      existingScreen: null,
      newScreen: null
    }
  },

  methods: {
    ...helperMethods,

    async handleTransfer () {
      if (!this.existingScreen || !this.newScreen) {
        toast('Please select both screens', 'danger')
        return
      }

      if (
        !await swal({
          text: `Are you sure you want to transfer data from [${this.existingScreen.name}] to [${this.newScreen.name}]?`,
          buttons: {
            ok: { text: 'Yes', visible: true, className: 'btn btn-success' },
            cancel: { text: 'No', visible: true, className: 'btn btn-dark' }
          },
          dangerMode: true
        })
      ) {
        return
      }

      try {
        const payload = {
          fromScreenId: this.existingScreen.id,
          toScreenId: this.newScreen.id
        }
        await http.post('/settings/screens/transfer', payload)
        toast('Successfully transfered screen data', 'success')
        this.existingScreen = null
        this.newScreen = null
      } catch (error) {
        toast('Something went wrong', 'danger')
      }
    }
  }
}
</script>

<style scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-body {
  padding: 1rem;
}

.form-label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 600;
}

.col-auto i {
  font-size: 1.25rem;
}

.btn-transfer-warning {
  background-color: #343a40;
  border-color: #343a40;
}
</style>
