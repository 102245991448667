<template>
  <c-input
    as="multiselect"
    :class="invalidClass"
    v-model="selected"
    placeholder="Select"
    :show-labels="false"
    :options="options"
    label="name"
    track-by="id"
  />
</template>

<script>
import { get, call } from 'vuex-pathify'
import { useIsInvalid } from '@chameleon/chameleon-vue'

export default {
  mixins: [useIsInvalid],

  props: {
    value: Object,
    prependAll: Boolean
  },

  computed: {
    screens: get('lists/screens/screens'),

    options () {
      const options = [...this.screens]

      return options
    },

    selected: {
      get () {
        return this.options.find(({ id }) => { if (this.value) { return id === this.value.id } })
      },

      set (option) {
        this.$emit('input', option ? { id: option.id, name: option.name } : null)
      }
    }
  },

  async created () {
    await this.getScreens()
  },

  methods: {
    getScreens: call('lists/screens/getScreens')
  }
}
</script>
